import React from "react";
import PropTypes from "prop-types";
import { graphql, withPrefix } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";

import { H1 } from "../components/Typography";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Breadcrumbs from "../components/Breadcrumbs";
import useSiteMetadata from "../components/SiteMetadata";
import { PostJsonLd } from "../components/JsonLd";

export const BlogPostTemplate = ({
  content,
  contentComponent,
  title,
  image,
  date,
  timeToRead,
  helmet,
  slug,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <>
      <section className="section">
        {helmet || ""}
        <div className="image-container">
          <div className="container">
            <Breadcrumbs
              inverted
              items={[
                { title: "MycoForest", href: "/", active: true },
                { title: "Blog", href: "/blog", active: true },
                {
                  title: title,
                  href: slug,
                  shortTitle: "Post",
                  active: false,
                },
              ]}
            />
            <H1 alignCenter={false}>{title}</H1>
            <div className="info">
              <p>{date}</p>
              <p>|</p>
              <p>{timeToRead} min read</p>
            </div>
          </div>
        </div>
        <div className="container">
          <PostContent className="markdown" content={content} />
        </div>
        <style jsx>{`
          .image-container {
            background-image: url(${image && image.childImageSharp
              ? image.childImageSharp.fluid.src
              : image});
            background-position: top left;
            background-attachment: fixed;
            background-repeat: no-repeat;
            background-size: cover;
            color: #fff;
            min-height: 300px;
            display: flex;
            align-items: flex-end;
            position: relative;
          }

          .image-container::after {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            background: rgba(0, 0, 0, 0.3);
            content: "";
          }

          .image-container :global(h1) {
            text-align: left;
            margin-bottom: 0;
          }

          .info {
            display: flex;
            align-items: center;
            font-family: "Lato", sans-serif;
          }

          .info p:not(:last-child) {
            margin-right: 8px;
          }

          .info p {
            margin-bottom: 0;
          }

          .container {
            max-width: 800px;
            margin: 0 auto;
            padding: 32px;
            position: relative;
            z-index: 2;
          }

          .image-container.container {
            padding: 20px 32px;
          }

          @media (max-width: 480px) {
            .container {
              padding: 32px 20px;
            }
          }
        `}</style>
      </section>
    </>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;
  const { siteUrl } = useSiteMetadata();

  const content = post.frontmatter;
  return (
    <Layout whiteHeader>
      <GatsbySeo
        title={content.seo.title}
        description={content.seo.description}
        openGraph={{
          url: `${siteUrl}${post.fields.slug}`,
          title: content.seo.title,
          description: content.seo.description,
          images: [{ url: `${siteUrl}${content.seo.image.publicURL}` }],
          site_name: "MycoForest",
        }}
      />
      <PostJsonLd post={content} slug={post.fields.slug} />
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        image={post.frontmatter.featuredimage}
        date={post.frontmatter.date}
        timeToRead={post.timeToRead}
        slug={post.fields.slug}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      timeToRead
      fields {
        slug
      }
      frontmatter {
        seo {
          title
          description
          authorName
          publisherName
          publishedLogo {
            publicURL
          }
          image {
            publicURL
          }
        }
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        featuredimage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
